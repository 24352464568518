html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

/*div{
  display: block;
}*/

body{
  font-size: 10px;
  font-family: Roost, Arial, Helvetica, sans-serif;
  background-image: url(bg.png);
  background-color: #333333;
  padding-left: 5%;
  padding-right: 5%;
}
h1, h2, h3, h4, h5, h6 {
  color: #3554E0;
}

.ROTATE{
  transform: rotate(90deg);
}

.s2imgimage{
  margin-right: 20em;
}

.SITE_STRUCTURE{
  position: relative;
  /* left: 15%;
  width: 980px;
  top: 0px;
  height: 1501px; */
}

#SITE_ROOT{
  padding-left: 10%;
  /* width: 80%; */
  /* width:"980px"; */
  /* padding-bottom:"41px"; */
  /*border: 3px solid rgb(46, 22, 234);*/
}

.darkBG{
  border: 2px solid transparent;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}

.bannerimg{
  position: relative;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 20em;
  /* width: 1166px;
  height: 218px; */
}

.Applogo{
  /* position: absolute; */
  /* top: 40%; */
  /* left: 40%; */
  width: 58%;
  /* max-width: 220px; */
  padding-left: 42%;
  /* height: 140px; */
  /* top: 25%; */
  /* left: 20%; */
  /* left: 35%;
  top: -100px; */
}

.navbar{
  width: calc(100% - 0px);
  display: inline-block; text-align: center; overflow: visible; height: 25px;
  position: absolute;
  top: 50%;
  /* left: 50%; */
  /* width: 1166px; */
  margin-top: 10%;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  border-radius: 0 50px 0 50px;
  bottom: 50px;
}
.navbarlink{
  width: 30%;
  height: 25px;
  box-sizing: border-box;
  overflow: visible;
  color: white !important;
  text-decoration-color: #2529ac;
  text-decoration-line: none;
  text-decoration-style: solid;
  /* border-radius: 0 50px 0 50px; */
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 0;
  display: inline-block;
  font: normal normal normal 15px/1.4em Jura,sans-serif;
  transition: color 0.4s ease 0s;
  padding: 0 calc(5px + 10px);
  background: transparent center center repeat-x;
  background-color: transparent;
  position: absolute;
  left: 6%;
  /* left: 0; */
  text-align: center;
  background-image: linear-gradient(#0089ff, #2529ac) !important;
}
.navbarlink2{
  left: 16%;
  font: normal normal normal 15px/1.4em Jura,sans-serif;
  display: inherit;
  width: 30%;
  height: 25px;
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  cursor: pointer;
  border-left: 1px solid rgba(42, 53, 54, 1);
  border-right: 1px solid rgba(42, 53, 54, 1);
  background: rgba(36, 28, 237, 1);
  transition: background-color 0.4s ease 0s;
  color: white !important;
  text-decoration-color: #2529ac;
  text-decoration-line: none;
  text-decoration-style: solid;
  margin: 0;
  padding: 0;
  outline: 0;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
  background-image: linear-gradient(#0089ff, #2529ac) !important;
}
.navbarlink3{
  left: 16%;
  font: normal normal normal 15px/1.4em Jura,sans-serif;
  display: inherit;
  width: 30%;
  height: 25px;
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  border-radius: 0 50px 0 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  cursor: pointer;
  border-left: 1px solid rgba(42, 53, 54, 1);
  background: rgba(36, 28, 237, 1);
  transition: background-color 0.4s ease 0s;
  color: white !important;
  text-decoration-color: #0089ff;
  text-decoration-line: none;
  text-decoration-style: solid;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
  background-image: linear-gradient(#0089ff, #2529ac) !important;
}
a.active{
  background: #93a7ff !important;
}
.firebaseui-idp-button:hover {
  background-image: linear-gradient(rgba(150, 200, 255, 1), #2529ac) !important;
}
.firebaseui-idp-button:hover > .firebaseui-idp-text {
  color: whitesmoke !important;
}
.btn:hover{
  background-image: linear-gradient(rgba(150, 200, 255, 1), #2529ac) !important;
}
.navbarlink:hover{
  background-image: linear-gradient(rgba(150, 200, 255, 1), #2529ac) !important;
}
.navbarlink2:hover{
  background-image: linear-gradient(rgba(150, 200, 255, 1), #2529ac) !important;
}
.navbarlink3:hover{
  background-image: linear-gradient(rgba(150, 200, 255, 1), #2529ac) !important;
}

.e31line{
  border-top: 3px solid rgba(36, 28, 237, 1);
  border-bottom: 1px solid rgba(36, 28, 237, 1);
  padding: 3px 0 0 0;
}

.e3line{
  border-top: 3px solid rgba(36, 28, 237, 1);
  padding: 3px 0 0 0;
}

.left_side_container{
  position: relative;
  /* width: 259px; */
  width: 20%;
  left: 0%;
  top: 31px;
  height: 1px
}

.middle_side_container{
  position: relative;
  /* width: 420px; */
  width: 38%;
  left: 24%;
  top: 31px;
  height: 1px
}

.right_side_container{
  position: relative;
  width: 22%;
  left: 68%;
  top: 31px;
  height: 1px
}


.e1line{
  border-bottom: 1px solid rgba(36, 28, 237, 1);
  height: 0 !important;
  min-height: 0 !important;
}

.font_8 {
  font: normal normal normal 14px/1.4em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.4em;
  font-family: Play, sans-serif;
  color: #CFDFE0;
}

.font_LB {
  font: normal normal normal 14px/1.4em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.4em;
  font-family: Play, sans-serif;
  color: #93a7ff;
}

.font_LB_mid {
  font: normal normal normal 19px/1.9em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 19px;
  line-height: 1.9em;
  font-family: Play, sans-serif;
  color: #93a7ff;
}

.font_LB_big {
  font: normal normal normal 18px/1.8em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 18px;
  line-height: 1.4em;
  font-family: Play, sans-serif;
  color: #0089ff; /*#93a7ff;*/
}

.ROTATE{
  left: 15%;
}

.joinBtn:hover > * > *{
  background-color:  rgba(150, 200, 255, 1) !important;
}
/* .joinBtn:hover:before{
  content: '';
  position: absolute;
  background: #fff;
  z-index: -1;
}
.joinBtn:hover:after{
  content: '';
  position: absolute;
  top: -2px;
  left: -200px;
  right: -200px;
  bottom: -200px;
  background: #fff;
  z-index: -2;
  filter: blur(400px);
}

.joinBtn:hover:before,
.joinBtn:hover:after{
  background: linear-gradient(235deg, #0089ff, white, #0089ff);
} */




.font_SimpleBlue {
  font: normal normal normal 14px/1.4em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.4em;
  font-family: Play, sans-serif;
  color: #3554e0;
}

.font_BigBlue {
  font: normal normal normal 20px/2.0em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  /* font-size: 20px; */
  line-height: 1.4em;
  font-family: Play, sans-serif;
  color: #3554e0;
}


.font_10 {
  font: normal normal normal 18px/1.8em Play,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 18px;
  line-height: 1.8em;
  font-family: Play, sans-serif;
  color: #CFDFE0;
}

.centered{
  text-align: center;
}

.e10paddin{
  padding: 10px;
}

.efontblue{
  color: rgba(36, 28, 237, 1) !important;
}

.rowSptlght{
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.spotimg:hover {
  background-color: rgba(150, 200, 255, 1) !important;
  color: black !important;
  opacity:1;transition: opacity 0.4s ease 0s;
}

.whitehover:hover{
  color: antiquewhite;
}

.spotimg:hover * {
  background-color: rgba(150, 200, 255, 1) !important;
  color: rgb(3, 0, 156) !important;
  font-weight: bold;
  /*opacity:1;transition: opacity 0.4s ease 0s;*/
}

.spotimg:hover > img{
  opacity: 0.6;
  filter: alpha(opacity=60);
}

p.dotted {
  width: 5em; /* the element needs a fixed width (in px, em, %, etc) */
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; /* give the beautiful '...' effect */
}

.smallbox{
  /*min-height: 70px;*/
  border: 1px solid rgba(36, 28, 237, 1);
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.shinyBox{
  position: relative;
  align-items: center;
}

.shinyBox:before{
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;
  z-index: -1;
}
.shinyBox:after{
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: #fff;
  z-index: -2;
  filter: blur(40px);
}

.shinyBox:before,
.shinyBox:after{
  background: linear-gradient(235deg, #0089ff, #060c21, #0089ff);
}


.rain
{
  height: 100%;
  background: url(rain.png);
  animation: rain .3s linear infinite;
}


.rain:before
{
content: '';
position:absolute;
width:100%;
height:100%;
background: white;
animation: lighting 4s linear infinite;
opacity:0;

}  

@media only screen and (max-width: 620px) {
  /* For mobile phones: */
  .navbar {
    display: none;
  }
  .left_side_container, .middle_side_container, .right_side_container {
    width: 100%;
  }
  .SITE_ROOT {
    display: flex;
    flex-direction: column;
  }
  .ROTATE {
    left: 10%;
  }
  .middle_side_container {
    order: 1;
    left: 0%;
  }
  .left_side_container {
    order: 2;
    display: none;
  }
  .right_side_container {
    order: 3;
    display: none;
  }
}

@keyframes rain 
{
0%
{
  background-position: 0% 0%;
}

100%
{
  background-position: 20% 100%;
}
} 